
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { ApiFulfillmentOrders } from "@/core/api";
import CommonProduct from "./CommonProduct.vue";

export default defineComponent({
  name: "kt-product-default-table",
  props: {
    id: {
      type: Number || String,
      required: true,
      default: "",
    },
  },
  components: {
    CommonProduct,
  },
  setup(props) {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const isRequest = ref<boolean>(false);
    const tableData = ref([]);

    const options = ref({});

    const getFulfillmentOrderDataItems = async () => {
      loading.value = true;
      const { data } = await ApiFulfillmentOrders.getFulfillmentOrderDataItems({
        id: props.id,
      });
      loading.value = false;
      if (data.code == 0) {
        tableData.value = data.data;
      }
    };

    onMounted(() => {
      getFulfillmentOrderDataItems();
    });

    return {
      t,
      loading,
      props,
      tableData,
      isRequest,
      options,
    };
  },
});
