
import { defineComponent, ref, onMounted } from "vue";
import { ApiBase, ApiMerchant } from "@/core/api";
import { useI18n } from "vue-i18n";
import {
  TaggingItem,
  MerchantOption,
  ChannelOption,
} from "@/core/directive/interface/common";
import { FulfillmentOrderFilter } from "@/core/directive/interface/fulfillmentOrder";
import { CommonFormType } from "@/core/directive/type/common";
import _ from "lodash";
import CommonChannelOption from "@/components/layout/CommonChannelOption.vue";

export default defineComponent({
  name: "filter-fulfillment-orders-dropdown",
  components: { CommonChannelOption },
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<CommonFormType>(null);
    const formData = ref<FulfillmentOrderFilter>({
      fulfillment_order_number: "",
      status: "",
      merchant: "",
      channel: [],
      sale_order_number: "",
      external_order_number: "",
      created_at_start: "",
      created_at_end: "",
    });

    const options = ref({
      status: [] as TaggingItem[], // getFulfillmentStatusOptions(t)
      merchant: [] as MerchantOption[],
      merchantLoading: false,
      channel: [] as ChannelOption[],
      channelLoading: false,
    });

    const getMerchantSourceData = async (value, id?) => {
      options.value.merchantLoading = true;
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData({
        ...params,
        options: { include_fields: ["name", "country_iso_2", "address"] },
      });
      options.value.merchantLoading = false;
      if (data.code == 0) {
        options.value.merchant = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["fulfillment_order_status"],
      });
      if (data.code == 0) {
        options.value.status = data.data.fulfillment_order_status.items;
      }
    };

    const getSalesChannelsData = async (id?, remake?) => {
      if (remake) {
        formData.value.channel = [];
        options.value.channel = [];
      }
      options.value.channelLoading = true;
      const { data } = await ApiMerchant.getSalesChannelsData({
        max_item: "all",
        merchant_id: id,
        status: "10",
      });
      options.value.channelLoading = false;
      if (data.code == 0) {
        options.value.channel = data.data;
      }
    };

    const getFromInfo = () => {
      // loading.value = true;
      Promise.all([getTaggingData(), getMerchantSourceData("")])
        .then(() => {
          // loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      getFromInfo();
    });

    return {
      t,
      formData,
      formRef,
      options,
      submit,
      handleReset,
      searchMerchantSourceItems,
      getSalesChannelsData,
    };
  },
});
