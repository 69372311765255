
import { defineComponent, ref, onMounted, onActivated, computed } from "vue";
import { MBDatatablePlus } from "magicbean-saas-common";
import FilterDropdown from "@/views/mb-order-routing/fulfillment-orders/FilterDropdown.vue";
import CancelFulfillmentOrderModal from "@/views/mb-order-routing/fulfillment-orders/CancelFulfillmentOrderModal.vue";
import FulfilledItemsTable from "@/components/table/FulfilledItems.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiFulfillmentOrders, ApiSalesOrders } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import _ from "lodash";
import fileDownload from "js-file-download";
import { useI18n } from "vue-i18n";
import {
  FilterOption,
  SortOption,
  TableHeader,
} from "@/core/directive/interface/common";
import { FulfillmentOrderTable } from "@/core/directive/interface/fulfillmentOrder";
import { NumberOrString } from "@/core/directive/type/common";
import { getFulfillmentStatus } from "@/core/directive/function/fulfillmentOrder";
import { FulfillmentOrderStatus } from "@/core/directive/type/fulfillmentOrder";
import { ProductItem } from "@/core/directive/interface/order";
import store from "@/store";
import {
  commonChangeFilterAddDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "mb-order-routing-fulfillment-orders",
  components: {
    MBDatatablePlus,
    FilterDropdown,
    CancelFulfillmentOrderModal,
    FulfilledItemsTable,
  },
  setup() {
    const { t } = useI18n();

    const { showFormSubmitSuccessMsg, showServerErrorMsg } = mixin();
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<FulfillmentOrderTable>>([]);
    const checkedFulfillmentOrder = ref<Array<number>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");
    const disabledExportReservation = ref<boolean>(false);
    const disabledExportOverview = ref<boolean>(false);
    const filterOptions = ref<Array<FilterOption>>([]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);

    const tableHeader = ref<Array<TableHeader>>([
      {
        name: t("fulfillmentOrder.fulfillmentOrder"),
        key: "fulfillment_order_number",
        sortable: false,
      },
      {
        name: t("fulfillmentOrder.reserveNo"),
        key: "external_order_number",
        sortable: false,
      },
      {
        name: t("fulfillmentOrder.exceptions"),
        key: "opening_exceptions",
        sortable: false,
        align: "right",
      },
      {
        name: t("fulfillmentOrder.createdDate"),
        key: "created_at",
        sortable: false,
      },
      {
        name: t("fulfillmentOrder.orderStatus"),
        key: "status",
        sortable: false,
      },
      {
        name: t("fulfillmentOrder.salesOrderIdentifier"),
        key: "sale_order_number",
        sortable: false,
      },
      {
        name: t("fulfillmentOrder.estimatedDelayDays"),
        key: "estimated_delay_days",
        sortable: false,
      },
      {
        name: t("fulfillmentOrder.merchantName"),
        key: "merchant",
        sortable: true,
      },
      {
        name: t("fulfillmentOrder.channel"),
        key: "channel",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const options = ref({
      FulfillmentOrderStatus: FulfillmentOrderStatus,
      loadingProducts: false,
      product_items: [] as ProductItem[],
    });

    const cancelId = ref(0);

    const init = () => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    const fileExportReservation = () => {
      if (disabledExportReservation.value === true) return;
      disabledExportReservation.value = true;
      ApiFulfillmentOrders.exportFulfillmentOrders({
        id: checkedFulfillmentOrder.value,
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExportReservation.value = false;
          const disposition = data.headers["content-disposition"];
          fileDownload(data.data, disposition.split("filename=")[1]);
        })
        .catch((error) => {
          disabledExportReservation.value = false;
          console.log(error);
        });
    };

    const fileExportOverview = () => {
      if (disabledExportOverview.value === true) return;
      disabledExportOverview.value = true;
      ApiFulfillmentOrders.exportFulfillmentOrderOverview({
        id: checkedFulfillmentOrder.value,
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExportOverview.value = false;
          const disposition = data.headers["content-disposition"];
          fileDownload(data.data, disposition.split("filename=")[1]);
        })
        .catch((error) => {
          disabledExportOverview.value = false;
          console.log(error);
        });
    };

    const getCompanyList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiSalesOrders.getFulfillmentOrderList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            tableData.value.forEach((item) => {
              item.closeDisabled = false;
            });
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsSelectChange = (selection) => {
      console.log(selection);
      let ids: number[] = [];
      selection.forEach((item) => {
        ids.push(item.id);
      });
      checkedFulfillmentOrder.value = ids;
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getCompanyList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (item == "created_at_start") {
            filterArr.push({
              field: "created_at",
              value: commonChangeFilterAddDate(val[item], 1),
              condition: "gteq",
            });
          } else if (item == "created_at_end") {
            filterArr.push({
              field: "created_at",
              value: commonChangeFilterAddDate(val[item]),
              condition: "lteq",
            });
          } else if (item == "status" || item == "channel") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "in",
            });
          } else if (item == "merchant") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getCompanyList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      // let sts = {};
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getCompanyList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const statusClass = computed(() => {
      return (status: NumberOrString) => {
        return getFulfillmentStatus(status);
      };
    });

    const cancelItem = (id) => {
      cancelId.value = id;
    };

    const closeItem = (item, id) => {
      Swal.fire({
        text: t("fulfillmentOrder.closeTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          item.closeDisabled = true;
          const { data } = await ApiFulfillmentOrders.closeFulfillmentOrderData(
            {
              id: id,
            }
          );
          item.closeDisabled = false;
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              updateList();
            });
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    return {
      t,
      formatDateTime,
      loading,
      tableHeader,
      options,
      tableData,
      checkedFulfillmentOrder,
      currentPage,
      total,
      pageSize,
      search,
      disabledExportReservation,
      disabledExportOverview,
      cancelId,
      getCompanyList,
      onRowsSelectChange,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      removeSearch,
      handleFilter,
      handleFilterReset,
      updateList,
      statusClass,
      cancelItem,
      closeItem,
      fileExportReservation,
      fileExportOverview,
    };
  },
});
